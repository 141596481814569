import * as React from 'react'
import { AppHeader } from '../AppHeader'
import { MainPage } from '@sleekly-ui/layout/MainPage'
import { Link } from 'react-router-dom'
import { NavMenuDrawer } from './NavMenuDrawer'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const Home: React.FC<Props> = ({ }: Props) => {

    return <div className='flex flex-col flex-1 overflow-auto'>
        <AppHeader></AppHeader>
        <div className="hero bg-base-200 min-h-screen pt-8">
            <div className="hero-content text-center">
                <div className="max-w-prose">
                    <h1 className="text-3xl sm:text-5xl  font-bold my-2">Construisons ensemble</h1>
                    <h1 className="text-3xl sm:text-5xl font-bold my-2">votre</h1>
                    <h1 className="text-3xl sm:text-5xl font-bold my-2">MVP 🚀 boosté par l'IA 🤖</h1>
                    <p className="py-6 text-xl sm:text-2xl">
                        Votre vision, notre expertise : développez des MVP intelligents grâce au Machine Learning et à l'IA, en un temps record !
                    </p>
                    <Link to="/submit_project" className="btn btn-primary">Proposer mon MVP</Link>
                </div>
            </div>
        </div>
        <div className="hero bg-base-200 sm:min-h-screen">
            <div className="hero-content flex flex-col text-center gap-0 p-4">
                <h1 className="text-3xl sm:text-5xl font-bold  my-4">Notre Offre</h1>
                <div className='flex flex-col md:flex-row items-stretch'>
                    <div className="card bg-base-100 md:w-96 shadow-xl m-2">
                        <div className="card-body">
                            <h2 className="text-2xl font-bold text-primary text-center">Validation de la vision</h2>
                            <p >Nous évaluons le marché ainsi que les attentes de vos utilisateurs, et surtout la bonne intégration de votre MVP dans les usages existants.</p>
                        </div>
                    </div>
                    <div className="card bg-base-100 md:w-96 shadow-xl m-2">
                        <div className="card-body">
                            <h2 className="text-2xl font-bold text-primary text-center">Validation Opérationnelle</h2>
                            <p>Nous évaluons les intégrations techniques avec vos systèmes, les données à exploiter, et envisageons les différents modèles de ML/IA compatibles avec votre budget d'exploitation.</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row items-stretch'>
                    <div className="card bg-base-100 md:w-96 shadow-xl m-2">
                        <div className="card-body">
                            <h2 className="text-2xl font-bold text-primary text-center">Réalisation Incrémentale</h2>
                            <p>Nous réalisons et validons à chaque étape l'adéquation du MVP avec la réalité de vos utilisateurs ainsi que la bonne intégration technique.</p>
                        </div>
                    </div>
                    <div className="card bg-base-100 md:w-96 shadow-xl m-2">
                        <div className="card-body">
                            <h2 className="text-2xl font-bold text-primary text-center">Déploiement Fluide</h2>
                            <p>Nous avons la possibilité d'héberger votre MVP ou de vous assister pour un déploiement sur votre propre infrastructure. Nous fournissons également les moyens d'évaluer l'utilisation et l'utilisabilité de votre MVP.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="hero bg-base-200 min-h-screen    md:my-0">
            <div className="hero-content text-center">
                <div className="max-w-prose">
                    <h1 className="text-3xl sm:text-5xl font-bold my-2">Qui sommes nous?</h1>
                    <p className="py-6 text-xl sm:text-2xl">
                    Notre Equipe est composée d'<span className='text-primary'>experts avec plus de 10 ans d'expérience</span> dans leurs domaines respectifs. Chaque membre est passionné par la <span className='text-primary'>création de produits intelligents</span> au service de l'<span className='text-primary'>utilisateur final</span>.
                    </p>
                </div>
            </div>
        </div>
    </div>
}