import { MainPage } from '@sleekly-ui/layout/MainPage'
import * as React from 'react'
import { AppHeader } from '../AppHeader'
import { AnalyticsPage } from '../analytics/AnalyticsPage'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const BackOffice: React.FC<Props> = ({ }: Props) => {

    return <MainPage>
        <AppHeader></AppHeader>
        <div className='flex-1 flex flex-col overflow-hidden mt-12'>
        <AnalyticsPage ></AnalyticsPage>
        </div>
    </MainPage>
}