import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { AppState } from '../AppState'

type Props = {
} & React.HTMLAttributes<HTMLDivElement>

export const NavDrawer: React.FC<Props> = ({ children, className }: Props) => {

    const [status, setStatus] = AppState.useState<boolean>(`nav-drawer-visible`);

    const toggleCheckbox = () => {
        setStatus(!status);
    };

    return <>
        <AnimatePresence>
            {
                status && <motion.div
                    initial={{
                        translateX: '-100%'
                    }}
                    animate={{
                        translateX: '0%'
                    }}
                    exit={{
                        translateX: '-100%'
                    }}
                    transition={{
                        ease: 'easeInOut'
                    }}
                    className={`fixed z-50 md:hidden w-full h-full rounded-lg top-0 left-0 backdrop-blur-sm`}
                    onClick={toggleCheckbox}>
                    <div className='fixed w-full h-full bg-black opacity-50 rounded-lg top-0 left-0 '
                    ></div>
                    <div className='relative z-50 py-2 px-4 w-8/12 h-full bg-base-200 opacity-100 flex flex-col p-2'>
                        {children}
                    </div>
                </motion.div>
            }
        </AnimatePresence>

    </>
}