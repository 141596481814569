import * as React from 'react'
import { NavDrawer } from '@sleekly-ui/layout/NavDrawer';
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle';
import { Link } from 'react-router-dom';
import { AppState } from '@sleekly-ui/AppState';
import { UserAuth } from '@zlab/UserAuth';
import { UserAvatar } from '@sleekly-ui/components/UserAvatar';
import { LogoutIcon } from '@sleekly-ui/icons/LogoutIcon';
import { logOut } from '../login/Login';

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const NavMenuDrawer: React.FC<Props> = ({ }: Props) => {

    const [user] = AppState.useState<UserAuth>("current-user");

    return <NavDrawer >
        <div className='flex space-x-2 my-4'>
            <img className="h-8 w-8 rounded-full" src="/resources/profile.jpeg"></img>
            <div className='text-lg font-bold'>ZebraLab - <span className='font-normal permanent-marker-regular'>MVP as a Service</span></div>
        </div>
        {
            user && <div className='flex items-center space-x-2 p-2 border rounded-md my-4'>
                <UserAvatar userName={user.full_name}></UserAvatar>
                <span className='flex-1 text-sm line-clamp-1'>{user.full_name}</span>
                <button className='btn btn-sm btn-square btn-ghost'
                    onClick={logOut}>
                    <LogoutIcon></LogoutIcon>
                </button>
            </div>
        }

        <div className='flex-1 flex flex-col'>
            <Link to="/" className='p-2 m-1 cursor-pointer hover:text-primary'>Présentation</Link>
            <Link to="/submit_project" className='p-2 m-1 cursor-pointer hover:text-primary'>Proposer mon MVP</Link>
            <Link to="/client" className='p-2 m-1 cursor-pointer hover:text-primary'>Espace Client</Link>
        </div>
        {
            user && user.roles.includes('Admin') && <div className='flex'>
                <Link to="/analytics" className='p-2 m-1 cursor-pointer btn btn-sm btn-primary w-full'>Admin</Link>
            </div>
        }
    </NavDrawer>
}