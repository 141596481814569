import * as React from 'react'
import { createBrowserRouter, Link, RouterProvider, useNavigate } from 'react-router-dom';
import { Login } from './login/Login';
import { AppState } from '@sleekly-ui/AppState';
import { AppPage } from '@sleekly-ui/layout/AppPage';
import { AnalyticsPage } from './analytics/AnalyticsPage';
import { Home } from './home/Home';
import { SubmitProject } from './home/SubmitProject';
import { MainPage } from '@sleekly-ui/layout/MainPage';
import { AppHeader } from './AppHeader';
import { BackOffice } from './home/BackOffice';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home></Home>,
    },
    {
        path: "/submit_project",
        element: <SubmitProject></SubmitProject>,
    },
    {
        path: "/client",
        element: <MainPage>
            <AppHeader></AppHeader>
            <Login defaultRoute="/client">
                <div className='flex flex-col items-center justify-center font-bold'>
                    <div className='text-2xl my-4'>Espace Client</div>
                    <img className="h-24 w-24 rounded-full" src="/resources/profile.jpeg"></img>

                </div>
            </Login>
        </MainPage>,
    },
    {
        path: "/login",
        element: <MainPage>
            <AppHeader></AppHeader>
            <Login defaultRoute="/">
                <div className='flex flex-col items-center justify-center font-bold'>
                    <div className='text-2xl my-4'>Espace Client</div>
                    <img className="h-24 w-24 rounded-full" src="/resources/profile.jpeg"></img>

                </div>
            </Login>
        </MainPage>,
    },
    {
        path: "/analytics",
        element: <BackOffice></BackOffice>,
    },
]);

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const App: React.FC<Props> = ({ }: Props) => {
    // AppState.pushState('app-theme', 'light');
    AppState.pushState('app-theme', 'dim');
    // AppState.pushState('app-theme', 'dark');
    // AppState.pushState('app-theme', 'synthwave');
    // AppState.pushState('app-theme', 'night');

    return <AppPage>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </AppPage>
}