import * as React from 'react'
import { useLoggedInUser } from '../login/Login'
import { MainPage } from '@sleekly-ui/layout/MainPage';
import { UserEnterEvent } from "@zlab/UserEnterEvent";
import { VisitorEnterEvent } from "@zlab/VisitorEnterEvent";

import { FlowaveClient } from '@sleekly-ui/flowave/FlowaveClient';
import { useNavigate } from 'react-router-dom';
import { List } from '@sleekly-ui/components/List';
import * as moment from 'moment';
import { ServerApi, ServerResponse } from '@sleekly-ui/ServerApi';
import { ProjectSubmission } from '@zlab/ProjectSubmission';
type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const AnalyticsPage: React.FC<Props> = ({ }: Props) => {

    const navigate = useNavigate();
    const user = useLoggedInUser();

    const [userEnterEvents, setUserEnterEvents] = React.useState<UserEnterEvent[]>([]);
    const [visitorEnterEvent, setVisitorEnterEvent] = React.useState<VisitorEnterEvent[]>([]);
    const [projectSubmission, setProjectSubmission] = React.useState<ProjectSubmission[]>([]);

    React.useEffect(() => {
        if (user) {
            loadEvents(user);
            loadVisitorEvents(user);
            loadProjectsEvents(user);
        }
    }, [user]);


    const loadEvents = async (user) => {
        const flowave = new FlowaveClient(`/api/analytics`, user.token);
        try {
            const events: UserEnterEvent[] = await flowave.getEntities('UserEnterEvent');
            events.sort((a, b) => b.date.localeCompare(a.date));
            setUserEnterEvents(events);
            // console.log(events);

        } catch (e) {
            navigate("/login");
            // console.log(e);
        }
    }

    const loadVisitorEvents = async (user) => {
        const flowave = new FlowaveClient(`/api/analytics`, user.token);
        try {
            const events: VisitorEnterEvent[] = await flowave.getEntities('VisitorEnterEvent');
            events.sort((a, b) => b.date.localeCompare(a.date));
            setVisitorEnterEvent(events);
            // console.log(events);

        } catch (e) {
            navigate("/login");
            // console.log(e);
        }
    }

    const loadProjectsEvents = async (user) => {
        try {
            const response: ServerResponse<ProjectSubmission[]> = await ServerApi.getJson(`/api/projects_subdmissions`, user.token);
            console.log(response.data);
            
            setProjectSubmission(response.data);

        } catch (e) {
            navigate("/login");
            // console.log(e);
        }
    }

    const visibleEvents = [].concat(userEnterEvents, visitorEnterEvent, projectSubmission);
    visibleEvents.sort((a, b) => b.date.localeCompare(a.date));

    return <MainPage className='p-4'>
        <List
            hideBorders={true}
            items={visibleEvents}
            renderItem={(item: any) => {
                return <div className='my-1 space-x-2 p-2 border'>
                    <span>{moment(item.date).format('DD/MM/YYYY - HH:mm')}</span>
                    <span>{item.kind}</span>
                    <span>{item.user_id}</span>
                    <span>{item.ip}</span>
                    <span>{item.host}</span>
                    <span>{item.user_agent}</span>
                    <span>{item.referer}</span>
                    <span>{item.accept_language}</span>
                    <span>{item.email}</span>
                    <span>{item.description}</span>

                </div>
            }}>
        </List>
    </MainPage>
}