import { AppState } from '@sleekly-ui/AppState';
import { UserAvatar } from '@sleekly-ui/components/UserAvatar';
import { LogoutIcon } from '@sleekly-ui/icons/LogoutIcon';
import { UserAuth } from '@zlab/UserAuth';
import * as React from 'react'
import { logOut, useCurrentUser } from './login/Login';
import { Link } from 'react-router-dom';
import { DrawerToggle } from '@sleekly-ui/layout/DrawerToggle';
import { NavMenuDrawer } from './home/NavMenuDrawer';

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const AppHeader: React.FC<Props> = ({ }: Props) => {

    const user = useCurrentUser();

    return <>
        <div className='fixed z-10 w-full flex items-center p-4 space-x-2 bg-base-100 bg-opacity-50 backdrop-blur-sm'>
            <DrawerToggle></DrawerToggle>
            <img className="h-8 w-8 rounded-full" src="/resources/profile.jpeg"></img>
            <div className='text-lg font-bold'>ZebraLab - <span className='font-normal permanent-marker-regular'>MVP as a Service</span></div>
            <div className='flex-1'></div>

            <div className='space-x-2 hidden md:flex'>
                <Link to="/" className='btn btn-sm btn-primary btn-ghost'>Présentation</Link>
                <Link to="/submit_project" className='btn btn-sm btn-primary btn-ghost'>Proposer mon MVP</Link>
                <Link to="/client" className='btn btn-sm btn-primary btn-ghost'>Espace Client</Link>
            </div>
            {
                user && <div className='flex items-center space-x-2 hidden md:flex'>
                    <UserAvatar userName={user.full_name}></UserAvatar>
                    <button className='btn btn-sm btn-square btn-ghost'
                        onClick={logOut}>
                        <LogoutIcon></LogoutIcon>
                    </button>
                </div>
            }
        </div>
        <NavMenuDrawer></NavMenuDrawer>
    </>
}