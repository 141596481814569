import * as React from 'react'
import { MenuIcon } from '../icons/MenuIcon'
import { AppState } from '../AppState'

type Props = {
} & React.HTMLAttributes<HTMLDivElement>

export const DrawerToggle: React.FC<Props> = ({ }: Props) => {

    const openDrawer = () => {
        AppState.pushState(`nav-drawer-visible`, true);
    }

    return <div className='md:hidden'>
        <button className='btn btn-square btn-sm btn-ghost'
                onClick={openDrawer}>
                <MenuIcon></MenuIcon>
            </button>
    </div>
}