import { MainPage } from '@sleekly-ui/layout/MainPage'
import * as React from 'react'
import { AppHeader } from '../AppHeader'

import { ProjectSubmission } from '@zlab/ProjectSubmission'
import * as moment from 'moment'
import { ServerApi, ServerResponse } from '@sleekly-ui/ServerApi'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const SubmitProject: React.FC<Props> = ({ }: Props) => {

    const [project, setProject] = React.useState<ProjectSubmission>({
        id: moment().toISOString(),
        date: moment().toISOString(),
        kind: "ProjectSubmission",
        email: '',
        description: ''
    });

    const submit = async () => {
        project.id = moment().toISOString();
        const response: ServerResponse<ProjectSubmission> = await ServerApi.postJson('/api/submit_project', project);
        setProject({
            id: moment().toISOString(),
            date: moment().toISOString(),
            kind: "ProjectSubmission",
            email: '',
            description: ''
        });
    }

    return <MainPage className='overflow-auto'>
        <AppHeader></AppHeader>
        <div className="hero bg-base-200 min-h-screen">
            <div className="hero-content text-center">
                <div className="max-w-prose space-y-4">
                    <h1 className="text-3xl font-bold my-2 mb-6">Proposer mon MVP</h1>
                    <input type="text" placeholder="Email" className="input input-md input-bordered w-full" itemType='email'
                        value={project.email}
                        onChange={e => setProject(Object.assign({}, project, {
                            email: e.target.value
                        }))} />
                    <textarea
                        placeholder="Description"
                        rows={8}
                        value={project.description}
                        className="textarea textarea-bordered textarea-md w-full"
                        onChange={e => setProject(Object.assign({}, project, {
                            description: e.target.value
                        }))}></textarea>
                    <button className='btn btn-primary'
                        disabled={project.email.length === 0 || project.description.length === 0}
                        onClick={submit}>Soumettre</button>
                </div>
            </div>


        </div>
    </MainPage>
}